const galleryLinks: NodeListOf<HTMLAnchorElement> =
  document.querySelectorAll("#home-categories-container a");

if (galleryLinks.length > 0) {
  galleryLinks.forEach((link) => {
    const dataId = link.getAttribute("data-id");
    if (dataId !== undefined) {
      link.href += `?id=${dataId}`;
    }
  });
}
